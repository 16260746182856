








































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import OrderApi, { BuyAirtimeInput } from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Order from "@/models/order";
import { mdiAlert, mdiCellphoneBasic } from "@mdi/js";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import firebase, {
    addAnalyticsEvent,
    ensureAuthenticated,
} from "@/plugins/firebase";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import StatusCodes from "http-status-codes";
import CompositeAlert from "@/components/CompositeAlert.vue";
import PhoneNumberApi from "@/services/api/phone-number";
import PhoneNetwork from "@/models/phone-network";
import UserProfile from "@/models/user-profile";
import { ApiResponse } from "@/services/api/axios";

export interface NetworkSelectItem {
    text: string;
    icon: string;
    value: string | number | boolean;
}

@Component({
    components: {
        CompositeAlert,
    },
})
export default class AirtimeIndex extends Vue {
    formEmail: string = "";
    formPaymentMethod: string = "mtn-mobile-money";
    formPhoneNumber: string = "";
    phoneIcon: string = mdiCellphoneBasic;
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    isValidatingPhoneNumber = false;
    formAmount = 150;
    formRecipientPhoneNumber: string = "";
    alertIcon: string = mdiAlert;
    formPhoneNetwork: string = "camtel-cameroon";

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Getter("referralCode") referralCode!: string;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (authToken: string | null) => void;

    get networks(): Array<NetworkSelectItem> {
        return [
            {
                value: "mtn-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/b589ad8d-f6c0-482e-a596-4360ba55b23d-size-1.png",
                text: "MTN Cameroon",
            },
            {
                value: "orange-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/fb214865-e85f-47cd-918f-ab69b7953e82-size-1.png",
                text: "Orange Cameroon",
            },
            {
                value: "nexttel-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/c97f15d8-d7a3-4a2f-87ef-63e863b5ce9b-size-1.png",
                text: "neXttel Cameroon",
            },
            {
                value: "camtel-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/8be9c8dd-b077-4f85-8d7a-d195749417a3-size-1.png",
                text: "Camtel Cameroon",
            },
            {
                value: "yoomee-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/13b8ba4d-c07f-400c-9b51-c6b89f65cfbb-size-1.png",
                text: "YooMee Cameroon",
            },
        ];
    }

    get phoneNetwork(): NetworkSelectItem | null {
        const network = this.networks.find(
            (x) => x.value === this.formPhoneNetwork
        );
        return network ?? null;
    }

    get phoneNetworkName(): string {
        return this.phoneNetwork?.text ?? "";
    }

    get phoneNetworkLogo(): string {
        return this.phoneNetwork?.icon ?? "";
    }

    get hasPhoneNetwork(): boolean {
        return (
            this.phoneNetwork !== null &&
            this.formRecipientPhoneNumber.trim().length > 0
        );
    }

    get formPhoneNumberPlaceholder(): string {
        if (!this.formPaymentMethod) {
            return "Phone Number e.g 6xxxxxxxx";
        }
        return `${this.paymentMethodString} Number e.g 6xxxxxxxx`;
    }

    get paymentMethodString(): string {
        if (this.formPaymentMethod === "orange-money") {
            return "Orange Money";
        }
        return "MTN Mobile Money";
    }

    buyAirtime() {
        addAnalyticsEvent("buy_airtime_clicked");
        this.isSubmitting = true;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleError = (error: any) => {
            this.isSubmitting = false;
            this.handleError(error);
        };

        this.$recaptcha("buy_airtime")
            .then((token: string) => {
                const payload: BuyAirtimeInput = {
                    captcha: token,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentMethod,
                    email: this.formEmail,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_network: this.formPhoneNetwork,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    recipient_phone_number: this.formRecipientPhoneNumber,
                    amount: parseInt(this.formAmount.toString()),
                };
                this.ensureUserExists()
                    .then(() => {
                        this.createOrder(payload);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
    }

    validatePhoneNumber() {
        this.isValidatingPhoneNumber = true;
        this.$recaptcha("validate_smartcard")
            .then((token: string) => {
                PhoneNumberApi.store(this.formRecipientPhoneNumber, token)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("recipient_phone_number");
                        const phoneNetwork = new PhoneNetwork(
                            response.data.data
                        );
                        this.formPhoneNetwork = phoneNetwork.id;
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isValidatingPhoneNumber = false;
                    });
            })
            .catch((error) => {
                this.isValidatingPhoneNumber = false;
                this.handleError(error);
            });
    }

    loadProfile() {
        if (!firebase.auth().currentUser?.uid) {
            captureSentryException(
                "cannot load profile for an unauthenticated user"
            );
            return;
        }

        UserApi.getProfile(firebase.auth().currentUser?.uid as string)
            .then((response: AxiosResponse) => {
                const userProfile = new UserProfile(response.data.data);
                this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
                this.formEmail = userProfile.email ?? this.user?.email ?? "";
                if (this.formRecipientPhoneNumber.length !== 9) {
                    this.formRecipientPhoneNumber =
                        userProfile.airtime?.recipientPhoneNumber ?? "";
                    this.formPhoneNetwork =
                        userProfile.airtime?.phoneNetwork ??
                        this.formPhoneNetwork;
                }
            })
            .catch((error: AxiosError<ApiResponse>) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            });
    }

    mounted() {
        if (typeof this.$route.query.p === "string") {
            this.formRecipientPhoneNumber = this.$route.query.p.trim();
        }
        if (this.user || this.userIsAnonymous) {
            ensureAuthenticated()
                .then(() => {
                    this.loadProfile();
                })
                .catch(this.handleError);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }

        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ensureUserExists(): Promise<any> {
        if (this.authToken) {
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                this.setUser(response.user);
                this.setAuthToken((await response.user?.getIdToken()) || null);
                addAnalyticsEvent("anonymous_user_created_before_order");
            });
    }

    createOrder(payload: BuyAirtimeInput) {
        OrderApi.buyAirtime(payload)
            .then((response: AxiosResponse) => {
                const order = new Order(response.data.data);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your order has been created successfully"
                );

                addAnalyticsEvent("begin_checkout", {
                    currency: "XAF",
                    value: order.paymentAmount,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            affiliation: this.phoneNetworkName,
                            currency: "XAF",
                        },
                    ],
                });

                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                    params: {
                        orderId: order.orderId,
                    },
                });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
